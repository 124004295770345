<template>
  <CRow>

    <CModal
        :title="
        submitType === 'Update'
          ? $lang.interviewers.crud.view
          : $lang.platform_ratting.crud.create
      "
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        color="primary"
    >
      <CCardBody>
        <div v-for="interviewer in viewInterviewer">
          <div><b>Name</b> : {{ interviewer.name }}</div>
          <div><b>Email</b> : {{ interviewer.email }}</div>
          <div><b>Phone</b> : {{ interviewer.phone }}</div>
          <div><b>Department</b> : {{ interviewer.department }}</div>
          <div><b>Description</b> :
            <div v-html="interviewer.description"></div>
          </div>
        </div>
      </CCardBody>
    </CModal>


    <CCol col="12">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="12" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', module, 'Interviewers',self.userId)"
                  ><i class="fas fa-file-pdf"></i></CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <CAlert :color="messageColor" closeButton :show.sync="dismissCountDownS" fade>{{ alertMessage }}</CAlert>
          <v-server-table :columns="columns" :url="listUrl" :options="options" ref="myTable"
                          @loaded="exports(self, 'Excel', module, 'Interviewers', self.userId)">
            <template #actions="data">
              <CButton
                  color="success"
                  v-c-tooltip="$lang.buttons.general.crud.edit"
                  v-on:click="viewTableRow(data.row.id)"
              >
                <i class="fas fa-eye"></i>
              </CButton>
            </template>

          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {interviewers} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import {required} from "vuelidate/lib/validators";
import VueToasted from "vue-toasted";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);
export default {
  name: "Interviewers",
  mixins: [Mixin],
  components: {
    VueJsonToCsv
  },
  data() {
    return {
      groupId: '',
      userId: '',
      submitted: false,
      listUrl: '/users/interviewers/list',
      json_data: [],
      viewInterviewer:[],
      messageColor: "success",
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      alertMessage: "",
      showAlert: false,
      dismissCountDownS: 0,
      msg: "",
      items: [],
      activePage: 1,
      selectedLetter: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      dismissCountDown: 0,
      statusOptions: [],
      self: this,
      module: interviewers,
      gac: {},
      maxlength: {
        // category: Maxlength.gac.name,
      },
      columns: ["name", "email","phone","department","actions"],
      data: [],
      options: {
        headings: {
          name: this.$lang.interviewers.table.name,
          email: this.$lang.interviewers.table.email,
          phone: this.$lang.interviewers.table.phone,
          department: this.$lang.interviewers.table.department,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["name"],
        sortable: ["name", "email","phone","department"],
        filterable: ["name"],
        // see the options API
      },
    };
  },
  validations: {
    gac: {
      name: {
        required,
      },
    },
  },

  created() {
    let self = this;
    self.dismissCountDown = 0;
    if (this.$route.params.id) {
      self.userId = this.$route.params.id;
      self.dismissCountDown = 0;
      self.listUrl = self.listUrl + "/" + self.userId;
    }
    store.commit('showLoader', false); // Loader Start
  },
  mounted() {
    store.commit('showLoader', false); // Loader Start
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    // self.data = this.loadData();
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    /* get from child component */
    switchFilter(show) {
      this.toggleFilterValue = show;
    },
    viewTableRow(item) {
      let self = this;
      self.submitType = "Update";
      const id = item;
      self.editId = item;
      axios
          .get("users/interviewers/" + id).then((response) => {
        if (response) {
          self.viewInterviewer = response.data.data;
        } else {
          self.data = [];
        }
      });
      self.largeModal = true;
    },

    createTableRow() {
      let self = this;
      self.$v.$reset();
      self.submitType = "Create";
      self.gac.name = "";
      self.gac.countryId = "";
      self.largeModal = true;
      self.gac.status = 1;
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
