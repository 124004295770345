<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          <strong>{{ registerName }}</strong>
          <back-to-list></back-to-list>
        </CCardHeader>

        <CCardBody>
          <CTabs add-tab-classes="mt-1">
            <span
                @click="selected = 'Details'"
            >

              <CTab active>
                <template slot="title" v-if="role==='Admin' ">
                  Admin
                </template>
                <template slot="title" v-else>
                   <template v-if="role=='Admin'">
                       {{ $lang.profile.heading.members }}
                   </template>
                  <template v-else>
                       {{ $lang.profile.heading.member }}
                   </template>
                </template>
              </CTab>
            </span>

            <span
                @click="selected = 'ViewPlanPermissions'"
                v-if="role==='Admin' "
            >
            <CTab>
            <template slot="title">
                          Member  Permissions
            </template>
            </CTab>
            </span>

            <span
                @click="selected = 'ProfileVerification'"
                v-if="role==='Admin'"
            >
              <CTab>
                <template slot="title">
                  {{ $lang.profile.tab.verification }}
                </template>
              </CTab>
            </span>

            <span @click="selected = 'GroupChangeList'"  v-if="role==='Admin'">
              <CTab>
                <template slot="title">
                 {{ $lang.profile.heading.jobDesignationRequests }}
                </template>
              </CTab>
            </span>

            <span
                v-if="role==='Admin'"
                @click="selected = 'PlanDetail'">
              <CTab>
                <template slot="title">
                   {{ $lang.profile.heading.planDetail }}
                </template>
              </CTab>
            </span>


            <span
                @click="selected = 'Branches'"
                v-if="role==='Admin'">
              <CTab>
                <template slot="title">
                   {{ $lang.profile.heading.branches }}
                </template>
              </CTab>
            </span>

            <span
                @click="selected = 'ProfileUpdateRequest'"
                v-if="role==='Admin'"
            >
              <CTab>
                <template slot="title">
                  {{ $lang.profile.tab.profile_request }}
                </template>
              </CTab>
            </span>



            <span

                @click="selected = 'ChildUsers'"
                v-if="numberOfUsers > 0 &&role==='Admin'"
            >
              <CTab>
                <template slot="title">
                 {{ $lang.profile.heading.members }}
                </template>
              </CTab>
            </span>

            <span v-if="role==='Admin'" @click="selected = 'MeetingList'">
              <CTab>
                <template slot="title">
                 {{ $lang.profile.heading.meetingList }}
                </template>
              </CTab>
            </span>
            <span
                v-if="role==='Admin'"
                @click="selected = 'Groups'"
            ><!--v-if="checkPermission('access-group')"-->
              <CTab>
                <template slot="title">
                 {{ $lang.profile.heading.community }}
                </template>
              </CTab>
            </span>

            <span
                @click="selected = 'Departments'"
                v-if="type=='Company' && role==='Admin'"
            >
              <CTab>
                <template slot="title">
                 {{ $lang.profile.heading.departments }}
                </template>
              </CTab>
            </span>

            <span
                v-if="type=='Company' && role==='Admin'"
                @click="selected = 'InterviewTemplates'"
            ><!--v-if="checkPermission('access-group')"-->
              <CTab>
                <template slot="title">
                 {{ $lang.profile.heading.templates }}
                </template>
              </CTab>
            </span>

            <span
                v-if="type=='Company' && role==='Admin'"
                @click="selected = 'CompanyRatings'"
            ><!--v-if="checkPermission('access-group')"-->
              <CTab>
                <template slot="title">
                 {{ $lang.profile.heading.ratings }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'Journey'" v-if="type==='Company' && role==='Admin'">
              <CTab>
                <template slot="title">
                   {{ $lang.profile.heading.journey }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'Interviewers'" v-if="type==='Company' && role==='Admin' ">
              <CTab>
                <template slot="title">
                   {{ $lang.profile.heading.interviewers }}
                </template>
              </CTab>
            </span>
          </CTabs>
          <component
              :is="selected"
              style="margin-top: 20px"
              v-bind:postId="currentId"
              :companyId="companyId"
              @changeSelected="getTab($event)"
          ></component>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import {Mixin} from "/src/mixins/index.js";
import ProfileLinks from "./tab/ProfileLinks";
import ProfileVerification from "./tab/ProfileVerification";
import ProfileUpdateRequest from "./tab/ProfileUpdateRequest";
import Details from "./tab/Details";
import Branches from "./tab/Branches";
import GroupChangeList from "./tab/GroupChangeList";
import Journey from "./tab/Journey";
import PlanDetail from "./tab/PlanDetail";
import MeetingList from "./tab/MeetingList";
import ChildUsers from "./tab/ChildUsers";
import Groups from "./tab/Groups";
import BackToList from "/src/views/backToList.vue";
import Departments from "./tab/Departments";
import InterviewTemplates from "./tab/InterviewTemplates";
import CompanyRatings from "./tab/CompanyRatings";
import Interviewers from "./tab/Interviewers";
import ViewPlanPermissions from "./tab/ViewPlanPermissions";
import {serverBus} from "/src/main";

export default {
  name: "ProfileTabs",
  mixins: [Mixin],
  components: {
    ViewPlanPermissions,
    Details,
    Branches,
    PlanDetail,
    ProfileVerification,
    ProfileUpdateRequest,
    ProfileLinks,
    ChildUsers,
    GroupChangeList,
    BackToList,
    MeetingList,
    Groups,
    Journey,
    Departments,
    InterviewTemplates,
    CompanyRatings,
    Interviewers
  },
  data() {
    return {
      selected: "Details",
      currentId: "",
      registerName: "",
      type: "", /* type Company or Individual*/
      role: "Verifier", // Admin  or User
      numberOfUsers: "",
      companyId: '',
    };
  },
  mounted() {
    let self = this;
    self.currentId = this.$route.params.id;

    serverBus.$on("send_company_id", function (companyId) {
      self.companyId = companyId;
    });
    serverBus.$on("number_of_admin_users", function (numberOfUsers) {
      self.numberOfUsers = numberOfUsers;
    });
    serverBus.$on("send_the_type", function (type) {
      self.type = type;
    });
    serverBus.$on("send_profile_name", function (name) {
      self.registerName = name;
    });
    serverBus.$on("send_role", function (role) {
      self.role = role;
    });

  },
  methods: {
    cancel() {
      //  this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/books'})
    },
    getTab(tab) {
      let self = this;
      self.selected = tab;
    },
  },
};
</script>
<style>
div .error {
  color: #ff0000;
}

.hasError label {
  color: red;
}
</style>

